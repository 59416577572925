<template>
  <div class="dec_section_title">
    <svg width="44px" height="18px">
      <defs>
        <linearGradient id="greenGradient1">
          <stop offset="0" stop-color="#07e0c6" stop-opacity="0.3">
            <animate
              attributeName="stop-opacity"
              from="0.3"
              to="1"
              begin="0.5s"
              dur="2s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="100%" stop-color="#07e0c6" stop-opacity="0.3">
            <animate
              attributeName="stop-opacity"
              from="0.3"
              to="1"
              dur="2s"
              begin="0.5s"
              repeatCount="indefinite"
            />
          </stop>
        </linearGradient>
      </defs>
      <defs>
        <linearGradient id="greenGradient2">
          <stop offset="0" stop-color="#07e0c6" stop-opacity="0.3">
            <animate
              attributeName="stop-opacity"
              from="0.3"
              to="1"
              dur="2s"
              begin="1s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="100%" stop-color="#07e0c6" stop-opacity="0.3">
            <animate
              attributeName="stop-opacity"
              from="0.3"
              to="1"
              dur="2s"
              begin="1s"
              repeatCount="indefinite"
            />
          </stop>
        </linearGradient>
      </defs>
      <defs>
        <linearGradient id="greenGradient3">
          <stop offset="0" stop-color="#07e0c6" stop-opacity="0.3">
            <animate
              attributeName="stop-opacity"
              from="0.3"
              to="1"
              dur="2s"
              begin="1.5s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="100%" stop-color="#07e0c6" stop-opacity="0.3">
            <animate
              attributeName="stop-opacity"
              from="0.3"
              to="1"
              dur="2s"
              begin="1.5s"
              repeatCount="indefinite"
            />
          </stop>
        </linearGradient>
      </defs>
      <defs>
        <linearGradient id="greenGradient4">
          <stop offset="0" stop-color="#07e0c6" stop-opacity="0.3">
            <animate
              attributeName="stop-opacity"
              from="0.3"
              to="1"
              dur="2s"
              begin="2s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="100%" stop-color="#07e0c6" stop-opacity="0.3">
            <animate
              attributeName="stop-opacity"
              from="0.3"
              to="1"
              begin="2s"
              dur="2s"
              repeatCount="indefinite"
            />
          </stop>
        </linearGradient>
      </defs>
      <polyline
        stroke-width="4"
        fill="transparent"
        stroke="url(#greenGradient1)"
        points="0 0,5 9,0 18"
      >
        <animate attributeName="rx" values="0;5;0" dur="10s" repeatCount="indefinite" />
      </polyline>
      <polyline
        stroke-width="4"
        fill="transparent"
        stroke="url(#greenGradient2)"
        points="12 0,17 9,12 18"
      />
      <polyline
        stroke-width="4"
        fill="transparent"
        stroke="url(#greenGradient3)"
        points="24 0,29 9,24 18"
      />
      <polyline
        stroke-width="4"
        fill="transparent"
        stroke="url(#greenGradient4)"
        points="35 0,40 9,35 18"
      />
    </svg>
    <slot />
  </div>
</template>
<script>
export default {
  props: ['title']
}
</script>
<style lang="scss" scoped>
.dec_section_title {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #fff;
  padding: 20px 0;

  svg {
    margin-right: 8px;
  }
}
</style>
